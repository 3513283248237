import React, { Component } from "react";
import { Flex, Box, Heading } from "rebass";
import Text from "../components/styles/Text";
import Link, { navigate } from "gatsby-link";
import styled from "styled-components";
import Page from "../components/Page";
import { graphql } from "gatsby";
import about from "../images/about.png";
import shop from "../images/shop.png";
import learn_more from "../images/learn_more.png";
import blog from "../images/blog.png";
import garage from "../images/garage.png";

import { colors } from "../theme";

const ALink = styled.a`
  :hover {
    cursor: pointer;
  }
`;

const MenuText = styled(Heading)`
  font-family: "Source Sans Pro", sans serif;
  font-style: italic;
  font-weight: 700 !Important;
  font-size: 28px;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  :hover {
    cursor: pointer;
  }
`;

const MenuIcon = styled.img`
  width: 50px;
`;

class menu extends Component {
  // fix so there's no undefined, go home if no props history available

  render() {
    return (
      <div>
        <Page>
          <a href="https://shop.modarri.com/">
            <Flex
              flexWrap="wrap"
              bg="#e7f8ff"
              p={5}
              justifyContent="center"
              alignItems="center"
            >
              <MenuIcon src={shop} />

              <MenuText color="#566066" px={4} pt={1}>
                {" "}
                SHOP
              </MenuText>
            </Flex>
          </a>
          {/* <Link to="/learn-more">
            <Flex
              flexWrap="wrap"
              bg="#e7f8ff"
              p={5}
              pl="40px"
              justifyContent="center"
              alignItems="center"
            >
              <MenuIcon src={learn_more} />
              <MenuText color="#566066" px={4} pt={1}>
                LEARN
              </MenuText>
            </Flex>
          </Link> */}
          <Link to="/garage">
            <Flex
              flexWrap="wrap"
              bg="white"
              p={5}
              pl="60px"
              justifyContent="center"
              alignItems="center"
            >
              <MenuIcon src={garage} />
              <MenuText color="#566066" px={4} pt={1}>
                {" "}
                GARAGE
              </MenuText>
            </Flex>
          </Link>

          <Link to="/blog">
            <Flex
              flexWrap="wrap"
              bg="#e7f8ff"
              p={5}
              justifyContent="center"
              alignItems="center"
            >
              <MenuIcon src={blog} />
              <MenuText color="#566066" px={4} pt={1}>
                BLOG
              </MenuText>
            </Flex>
          </Link>
          <Link to="/about">
            <Flex
              flexWrap="wrap"
              bg="white"
              p={5}
              pl="50px"
              mb="-60px"
              justifyContent="center"
              alignItems="center"
            >
              <MenuIcon src={about} />
              <MenuText color="#566066" px={4} pt={1}>
                ABOUT
              </MenuText>
            </Flex>
          </Link>
        </Page>
      </div>
    );
  }
}

export default menu;
